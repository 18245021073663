import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { TablesService, Element } from '../../tables/tables.service';
import { TrainingsService } from '../training.service'
import { MatSort } from '@angular/material/sort';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '../../../shared/dialog/dialog.component';
import { FormControl, UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-boot-camp-videos',
  templateUrl: './boot-camp-videos.component.html',
  styleUrls: ['./boot-camp-videos.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [TrainingsService]
})
export class BootCampVideosComponent implements OnInit {

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  @ViewChild(MatSort, { static: true }) sort!: MatSort;
  dataLength: number;
  matDialogRef: MatDialogRef<DialogComponent>;
  name: string = "";
  matDialog: any;
  searchCtrl = new FormControl();
  hideClear: boolean = false;
  MatSort: any;

  openDialog(val): void {
    let dialogRef = this.dialog.open(DialogComponent, {
      height: '300px',
      width: '700px',
      data: { name: val }
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public dataSource: any;
  public settings: Settings;
  recordVideos: any = [];
  public displayedColumns = ['CreatedDate', 'VideoTitle', 'LifePlanVersion', 'actions'];

  constructor(public appSettings: AppSettings,
    private tablesService: TablesService,
    public trainingService: TrainingsService,
    public dialog: MatDialog,
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit(): void {
    this.getVideos();
  }

  /* List Of Required Videos Calling */
  public getVideos(): void {
    this.dataSource = null; //for show spinner each time
    this.trainingService.getBootcampVideos()
      .subscribe(data => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.data = data;
        this.dataLength = data.length;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
      );
  }

  /* Search Function Start */

  applyFilter(value: any) {
    this.dataSource.filter = value.trim().toLowerCase();
    //  this.isNoRecords = this.dataSource.filteredData.length > 0 ? false : true;
    this.dataSource.data = this.dataSource.filteredData;
    this.dataLength = this.dataSource.data.length;
    this.hideClear = true;
    if (value.trim() === "") {
      this.hideClear = false;
      this.getVideos();
    }
  }

  clearSearch() {
    this.searchCtrl.setValue('');
    this.hideClear = false;
    this.getVideos();
  }
  /* Search Function End */

   /* sorting  function Start Here */

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

 
}
