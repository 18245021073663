import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AdditionalTrainingModel, BootCampHandOutsModel, BootcampVideos, howToVideos, RequiredVideos } from './Training.model';
import { environment } from 'src/environments/environment';
@Injectable()
export class TrainingsService {
    public _enveronment=environment.config.API;
    public url = this._enveronment+"Training/GetrequiredVideos";
    public GetRequiredVideosurl = this._enveronment+"Training/GetrequiredVideos";
    public GetBootcampVideosUrl = this._enveronment+"Training/GetBootcampVideos";
    public GetAdditionalTrainingUrl = this._enveronment+"Training/GetAdditionalTrainingVideos";
    public GetHowToVideos = this._enveronment+"Training/GetHowToVideos";
    public getBootCampHandOuts = this._enveronment+"Training/GetBootCampHandoutFiles";
    constructor(public http: HttpClient) { }

    getRequiredVideos(): Observable<RequiredVideos[]> {
        return this.http.get<RequiredVideos[]>(this.GetRequiredVideosurl);
    }
    getBootcampVideos(): Observable<BootcampVideos[]> {
        return this.http.get<BootcampVideos[]>(this.GetBootcampVideosUrl);
    }
    getBootcampHandOutsVideos(): Observable<BootCampHandOutsModel[]> {
        return this.http.get<BootCampHandOutsModel[]>(this.getBootCampHandOuts);
    }
    getAdditionalTraining(): Observable<AdditionalTrainingModel[]> {
        return this.http.get<AdditionalTrainingModel[]>(this.GetAdditionalTrainingUrl);
    }
    GetHowToVideosData(): Observable<howToVideos[]> {
        return this.http.get<howToVideos[]>(this.GetHowToVideos);
    }
    addUser(user: RequiredVideos) {
        return this.http.post(this.url, user);
    }

    updateUser(user: RequiredVideos) {
        return this.http.put(this.url, user);
    }

    deleteUser(id: number) {
        return this.http.delete(this.url + "/" + id);
    }
} 
