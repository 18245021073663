import { Component, OnInit,Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  url;
  format;
constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    //if(this.data)
    //var lastFive = this.data.name.substr(this.data.length - 3);
    //console.log(lastFive)
  }
  
   
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  
}
