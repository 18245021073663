import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = "assets/img/users/user.jpg";
  public username:string="";
  constructor( public router: Router) { }

  ngOnInit() {
    this.username=localStorage["UserFirstName"]+" "+ localStorage["UserLastName"];
  }
Logout()
{
  localStorage.clear();
  this.router.navigate(['/login']);
}
}
