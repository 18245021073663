import { Component, ViewChild} from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { Router } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
  constructor(public appSettings:AppSettings,public router: Router){
      this.settings = this.appSettings.settings;
  } 

  ngOnInit() { 
    debugger;
    let path=document.location.pathname;
    let currentpath='';
    if(path.length>1)
    {
currentpath=path[path.length-1];
    }
 if(!localStorage["SID"] && path.indexOf('signup') ==-1 && path.indexOf('viewplan') ==-1 )

 this.router.navigate(['/login']);
   } 
}